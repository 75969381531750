<style scoped>
.section-page-content{
  padding:0;
  background-color: #fff;
}
.section-page-content .event-page-title{
  position:absolute;
  z-index:1;
  width:calc(100% - 520px);
  right:0;
  height:100px;
  border-bottom:1px solid #dedede;
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding:0 25px
}
.section-page-content .event-page-title .entry-title{
  color:#04092c;
  text-transform:capitalize;
  font-weight:700;
  margin:0 0 7px 0;
  padding:0;
  border:0;
  font-size:24px;
  line-height:30px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}
.event-location-details .eventime-location{
  display:flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.event-location-details .eventime-location span:first-child{
  width: calc(100% - 230px);
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}
.event-location-details .eventime-location span:last-child{
  margin:0 30px 0 0px;
  position:relative;
}
.event-location-details .eventime-location span:last-child:before{
  content:'';
  position:absolute;
  right:-15px;
  top:0;
  height:100%;
  width:2px;
  background-color:#a0a2b3
}
.event-location-details .eventime-location span{
  color:#04092c;
  font-size:14px;
  line-height:24px
}
.event-location-details .eventime-location span i{
  margin:0 6px 0 0
}
.inner-header.event-details-header .innerpage-header .main-header{
  border-bottom:1px solid #ccc
}
@media (max-width: 1440px) {
  .section-page-content .event-page-title{
    width: calc(100% - 420px);
    height:85px
  }
  .section-page-content .event-page-title .entry-title{
    font-size:20px;
    line-height:24px
  }
  .event-location-details .eventime-location span{
    font-size: 13px;
    line-height: 22px;
  }
}
@media (max-width: 1280px) {
  .section-page-content .event-page-title{
    width:calc(100% - 400px)
  }
  .section-page-content .event-page-title .entry-title{
    font-size:18px;
    line-height:24px
  }
  .event-location-details .eventime-location span{
    font-size: 12px;
    line-height: 20px;
  }
}
@media (max-width: 991px) {
  .section-page-content .event-page-title{
    width:100%;
    height: 80px;
    padding: 5px 20px;
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .section-page-content .event-page-title{
    padding:5px 15px;
    height: 70px;
  }
  .section-page-content .event-page-title .entry-title{
    font-size:16px;
    line-height:22px;
    margin:0 0 3px 0;
    text-align: center;
  }
  .event-location-details .eventime-location span{
    font-size:12px;
    line-height:16px
  }
  .event-location-details .eventime-location span:first-child{
    width:calc(100% - 185px)
  }
}
@media (max-width: 480px) {
  .event-location-details .eventime-location span{
    font-size:12px;
    line-height:16px
  }
}
@media (max-width: 400px) {
  .section-page-content .event-page-title{
    height:65px;
    padding:5px 8px;
  }
  .section-page-content .event-page-title .entry-title{
    font-size:14px;
    line-height:20px
  }
  .event-location-details .eventime-location span{
    font-size:11px;
    line-height:13px
  }
  .event-location-details .eventime-location span:last-child{
    margin: 0 16px 0 0px;
  }
  .event-location-details .eventime-location span:last-child:before{
    right: -8px;
  }
}
@media (max-width:991px) and (orientation:landscape){
  .section-page-content .event-page-title .entry-title{
    font-size:18px;
    line-height:24px
  }
}
</style>
<template>
  <section class="section-page-content">
    <div class="event-page-title">
      <div class="event-location-details" v-for="(event, index) in event" :key="index">
        <h1 class="entry-title">{{ event.eventName.replace(/ *\([^)]*\) */g, '') }}</h1>
        <div class="eventime-location">
          <span class="location"><i class="fa fa-map-marker"></i>{{ event.venue.name }} - {{ event.venue.city }} - {{ event.venue.state }} - {{ event.venue.timezone }}, {{ event.venue.countryCode }}</span>
          <span class="time-date"><i class="fa fa-calendar-o"></i>{{ event.eventDate }}</span>
        </div>
      </div>
    </div>
    <div id="eventMap">
    </div>
    <div class="mobile-map-toggle">
      <button class="map-toggle-btn"><i class="fa fa-map-marker" aria-hidden="true"></i> <span>Show Event Map</span></button>
    </div>
    <span class="resale-ticket-info"><i class="fa fa-info-circle"></i> We are a resale marketplace. Ticket prices may exceed face value. This site is not a primary ticket seller or box office.</span>
  </section>
</template>

<script>
/* eslint-disable */
import $ from 'jquery'
import axios from 'axios'

export default {
  name: 'EventDetail',
  beforeCreate () {
    document.title = process.env.VUE_APP_NAME
  },
  data: function () {
    return {
      event: [],
      eventPerformerName: '',
      enableAnytrack: process.env.VUE_APP_ANYTRACK,
    }
  },
  el: '.buyButton',
  mounted () {
    $("body").on("click", '.mobile-map-toggle', function (e) {
      $(".map-toggle-btn span").text( $(".map-toggle-btn span").text() === "Show Event Map" ? "Hide Event Map" : "Show Event Map" );
      $("body").toggleClass('mobile-map-open');
      $('.event-name-map').toggleClass('show-map');
          // $(".event-name-map").css({ "visibility":"visible", "opacity": "1"});
    })
    if (this.enableAnytrack !== ''){
      this.$el.addEventListener('click', ({ target }) => {
        var eventId = target.closest('.tp_ticketCell').getAttribute('id');
        var eventname = document.getElementById(eventId).getElementsByClassName("tp_sectionNumber")[0].innerText
        var eventquantity = document.getElementById(eventId).getElementsByClassName("tp_quantity")[0].value
        var eventprice = document.getElementById('ticket_VB5544324314').getElementsByClassName("tp_cellPrice")[0].innerText.replace(/[^0-9.]/g, '')
        var clickId = AnyTrack('trigger', 'AddToCart',
          { items: [{ id: eventId, name: eventname, quantity: eventquantity, price: eventprice }] })
      })
      // this.$el.addEventListener("click", this.myFunction);
      // $(document).on('click', '#ticketContainer #TicketList a', function (e) {
      //   var eventId = $(this).closest('.tp_ticketCell').attr('id')
      //   var eventname = $('#' + eventId + ' .tp_sectionNumber').html()
      //   var eventquantity = $('#' + eventId + ' .tp_quantity').val()
      //   var eventprice = parseFloat($('#' + eventId + ' .tp_cellPrice').text().replace(/[^0-9.]/g, ''))
      //   // var clickId = AnyTrack('trigger', 'AddToCart',
      //   //   { items: [{ id: eventId, name: eventname, quantity: eventquantity, price: eventprice }] })
      // })
    }
    this.$nextTick(function () {
      this.loadMap()
      this.getEvent()
    })
  },
  methods: {
    loadMap: function (event) {
      /* eslint-disable */
      var script_tag = document.createElement('script');
      script_tag.type = 'text/javascript';
      var eventId = this.$route.params.id ? this.$route.params.id : '123';
      var eventSlug = this.$route.params.eventName ? this.$route.params.eventName : 'event';
      script_tag.text = 'var tpOptions = {"eventId":"'+ this.$route.params.id +'", "wsUser":"'+ process.env.VUE_APP_TFS_USER_ID +'", "wsVar":null, "checkoutUrl":"'+ process.env.VUE_APP_TFS_CHECKOUT_URL +'", "checkoutRedirectUrl":"'+ window.location.origin + '/payment/status' + '/' + eventId + '/' + eventSlug +'", "restApiUrl":"https:\/\/webservices.secure-tix.com\/rest\/v2\/", "clientEndpoint":"https:\/\/assets.secure-tix.com\/ticket-plugin\/3.0.2\/", "targetContainer":"#eventMap", "doResponsive":true, "doHoverBlink":true, "enableAllInPricing":false, "showAllInPricing":false, "showFaceValue":true, "rollupGAListings":true, "showQuantityPopup":false};';
      document.body.appendChild(script_tag);
      let ticketPagePluginScript = document.createElement('script')
      ticketPagePluginScript.setAttribute('src', 'https://assets.secure-tix.com/ticket-plugin/3.0.2/bundle.js')
      document.body.appendChild(ticketPagePluginScript)
      /* eslint-enable */
    },
    getEvent: function () {
      axios.get(process.env.VUE_APP_API_URL + '/getEvent', {
        params: {
          eventId: this.$route.params.id
        }
      }).then(response => {
        if (response.status === 200) {
          if (response.data.data === null) {
            window.location = window.location.origin + '/404'
          } else {
            var performerName = response.data.data.eventName.replace(/ *\([^)]*\) */g, '')
            var performerCity = response.data.data.venue.city
            var performerVenue = response.data.data.venue.name
            var performerDate = new Date(response.data.data.eventUTCDateString).toLocaleDateString()
            if (performerName && performerCity && performerVenue && performerDate) {
              var performerTitle = performerName + ' ' + performerCity + ' tickets' + ' - ' + performerVenue + ' - ' + performerDate
              document.title = performerTitle + ' | ' + process.env.VUE_APP_NAME
            }
            this.event.push(response.data.data)
          }
        }
      })
    }
  }
}
</script>
